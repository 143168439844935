var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"Card_Apuracao clickable",class:("elevation-" + (hover ? 5 : 1)),attrs:{"fill-height":""},on:{"click":function($event){return _vm.selectItem(_vm.item)}}},[_c('v-card-title',{staticClass:"Card_Title pa-2 justify-center",staticStyle:{"height":"45%"}},[_c('div',{staticClass:"text-center mb-0",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.$te(_vm.item.titulo, 1) ? _vm.$tc(_vm.item.titulo, 1) : _vm.item.titulo)+" ")])]),_c('v-card-text',{staticClass:"Card_Content",staticStyle:{"height":"25%"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('p',{staticClass:"headline font-weight-light",style:({color: _vm.corValorPercentual(_vm.item)})},[_vm._v(" "+_vm._s(_vm.getPercent(_vm.item.valorPercentual))+" ")])])],1),_c('v-card-actions',{staticClass:"Card_Actions",staticStyle:{"height":"30%"}},[_c('v-row',{staticClass:"text-xs-center",attrs:{"justify":"center","align":"end"}},[(_vm.indModoVarejo)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":!_vm.habilitarFinalizarApuracao && !_vm.habilitarCancelarApuracao ? 6 : (_vm.habilitarFinalizarApuracao && _vm.habilitarCancelarApuracao) ? 3 : 4}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({attrs:{"justify":"center","align":"center"}},on),[_c('v-col',{staticClass:"py-0",staticStyle:{"text-align":"center","white-space":"nowrap"},attrs:{"cols":"12","sm":"12"}},[_vm._v(" "+_vm._s(_vm.getMoney(_vm.item.totalRecebimentos))+" "),_c('br'),_c('v-icon',[_vm._v("attach_money")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.total_de_recebimento')))])])],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":!_vm.habilitarFinalizarApuracao && !_vm.habilitarCancelarApuracao ? 6 : (_vm.indModoVarejo && _vm.habilitarFinalizarApuracao && _vm.habilitarCancelarApuracao) ? 3 : 4}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({attrs:{"justify":"center","align":"center"}},on),[_c('v-col',{staticClass:"py-0",staticStyle:{"text-align":"center","white-space":"nowrap"},attrs:{"cols":"12","sm":"12"}},[_vm._v(" "+_vm._s(_vm.item.quantidade)+" "),_c('br'),_c('v-icon',[_vm._v("functions")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.quantidade_apuracao')))])])],1),(_vm.habilitarFinalizarApuracao)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":(!_vm.indModoVarejo || !_vm.habilitarCancelarApuracao) ? 4 : 3}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({attrs:{"justify":"end","align":"center"}},on),[_c('v-col',{staticClass:"py-0",staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12"}},[_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":""},on:{"click":function (e) { return _vm.abrirFinalizacaoLote(e, _vm.item); }}},on),[_c('v-icon',[_vm._v("done_all")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.finalizar_apuracao', 2)))])])],1):_vm._e(),(_vm.habilitarCancelarApuracao)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":(!_vm.indModoVarejo || !_vm.habilitarFinalizarApuracao) ? 4 : 3}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',_vm._g({attrs:{"justify":"end","align":"center"}},on),[_c('v-col',{staticClass:"py-0",staticStyle:{"text-align":"center"},attrs:{"cols":"12","sm":"12"}},[_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":""},on:{"click":function (e) { return _vm.abrirCancelamentoLote(e, _vm.item); }}},on),[_c('v-icon',[_vm._v("cancel")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('label.cancelar_apuracoes')))])])],1):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }